import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _kebabCase3 from "lodash/kebabCase";

var _kebabCase = "default" in _kebabCase3 ? _kebabCase3.default : _kebabCase3;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2.default;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assignComponents = assignComponents;
  exports.registerComponent = registerComponent;
  exports.default = void 0;

  var _kebabCase2 = _interopRequireDefault(_kebabCase);

  const components = {};

  function assignComponents(target, source) {
    for (const component of source) {
      target[component.componentName || (0, _kebabCase2.default)(component.name)] = component;
    }
  }

  function registerComponent(Component) {
    assignComponents(components, [Component]);
  }

  var _default = components;
  exports.default = _default;
  return exports;
}