import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _map3 from "lodash/map";

var _map = "default" in _map3 ? _map3.default : _map3;

import * as _forEach3 from "lodash/forEach";

var _forEach = "default" in _forEach3 ? _forEach3.default : _forEach3;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var _interopRequireDefault = _interopRequireDefault2.default;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildFontsTags = buildFontsTags;

  var _map2 = _interopRequireDefault(_map);

  var _forEach2 = _interopRequireDefault(_forEach); // eslint-disable-next-line import/prefer-default-export


  function buildFontsTags(content, inlineStyle, fonts = {}) {
    const toImport = [];
    (0, _forEach2.default)(fonts, (url, name) => {
      const regex = new RegExp(`"[^"]*font-family:[^"]*${name}[^"]*"`, "gmi");
      const inlineRegex = new RegExp(`font-family:[^;}]*${name}`, "gmi");

      if (content.match(regex) || inlineStyle.some(s => s.match(inlineRegex))) {
        toImport.push(url);
      }
    });

    if (toImport.length > 0) {
      return `
      <!--[if !mso]><!-->
        ${(0, _map2.default)(toImport, url => `<link href="${url}" rel="stylesheet" type="text/css">`).join("\n")}
        <style type="text/css">
          ${(0, _map2.default)(toImport, url => `@import url(${url});`).join("\n")}
        </style>
      <!--<![endif]-->\n
    `;
    }

    return "";
  }

  return exports;
}