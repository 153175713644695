import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _path3 from "path";

var _path2 = "default" in _path3 ? _path3.default : _path3;

import * as _fs3 from "fs";

var _fs2 = "default" in _fs3 ? _fs3.default : _fs3;

import * as _mjmlValidator3 from "mjml-validator";

var _mjmlValidator2 = "default" in _mjmlValidator3 ? _mjmlValidator3.default : _mjmlValidator3;

import { dew as _components2Dew } from "../components";
import _process from "process";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  function _nullRequire(id) {
    var e = new Error("Cannot find module '" + id + "'");
    e.code = "MODULE_NOT_FOUND";
    throw e;
  }

  _nullRequire.resolve = _nullRequire;
  var process = _process;
  var _interopRequireDefault = _interopRequireDefault2.default;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.readMjmlConfig = readMjmlConfig;
  exports.resolveComponentPath = resolveComponentPath;
  exports.registerCustomComponent = registerCustomComponent;
  exports.handleMjmlConfigComponents = handleMjmlConfigComponents;
  exports.default = handleMjmlConfig;

  var _path = _interopRequireDefault(_path2);

  var _fs = _interopRequireDefault(_fs2);

  var _mjmlValidator = _mjmlValidator2;

  var _components = _components2Dew();

  function readMjmlConfig(configPathOrDir = process.cwd()) {
    let componentRootPath = process.cwd();
    let mjmlConfigPath = configPathOrDir;

    try {
      mjmlConfigPath = _path.default.basename(configPathOrDir).match(/^\.mjmlconfig(\.js)?$/) ? _path.default.resolve(configPathOrDir) : _path.default.resolve(configPathOrDir, ".mjmlconfig");
      componentRootPath = _path.default.dirname(mjmlConfigPath);

      const fullPath = _path.default.resolve(mjmlConfigPath);

      let mjmlConfig;

      if (_path.default.extname(mjmlConfigPath) === ".js") {
        delete {}[fullPath];
        mjmlConfig = _nullRequire(fullPath); // eslint-disable-line global-require, import/no-dynamic-require
      } else {
        mjmlConfig = JSON.parse(_fs.default.readFileSync(fullPath, "utf8"));
      }

      return {
        mjmlConfig,
        componentRootPath
      };
    } catch (e) {
      if (e.code !== "ENOENT") {
        console.error("Error reading mjmlconfig : ", e); // eslint-disable-line no-console
      }

      return {
        mjmlConfig: {
          packages: [],
          options: {}
        },
        mjmlConfigPath,
        componentRootPath,
        error: e
      };
    }
  }

  function resolveComponentPath(compPath, componentRootPath) {
    if (!compPath) {
      return null;
    }

    if (!compPath.startsWith(".") && !_path.default.isAbsolute(compPath)) {
      try {
        return _nullRequire(compPath);
      } catch (e) {
        if (e.code !== "MODULE_NOT_FOUND") {
          console.error("Error resolving custom component path : ", e); // eslint-disable-line no-console

          return null;
        } // we got a 'MODULE_NOT_FOUND' error


        try {
          // try again as relative path to node_modules: (this may be necessary if mjml is installed globally or by npm link)
          return resolveComponentPath(`./node_modules/${compPath}`, componentRootPath);
        } catch (e) {
          //  try again as a plain local path:
          return resolveComponentPath(`./${compPath}`, componentRootPath);
        }
      }
    }

    return _nullRequire(_path.default.resolve(componentRootPath, compPath));
  }

  function registerCustomComponent(comp, registerCompFn = _components.registerComponent) {
    if (comp instanceof Function) {
      registerCompFn(comp);
    } else {
      const compNames = Object.keys(comp); // this approach handles both an array and an object (like the mjml-accordion default export)

      compNames.forEach(compName => {
        registerCustomComponent(comp[compName], registerCompFn);
      });
    }
  }

  function handleMjmlConfigComponents(packages, componentRootPath, registerCompFn) {
    const result = {
      success: [],
      failures: []
    };
    packages.forEach(compPath => {
      let resolvedPath = compPath;

      try {
        resolvedPath = resolveComponentPath(compPath, componentRootPath);

        if (resolvedPath) {
          const requiredComp = _nullRequire(resolvedPath); // eslint-disable-line global-require, import/no-dynamic-require


          registerCustomComponent(requiredComp.default || requiredComp, registerCompFn);
          (0, _mjmlValidator.registerDependencies)((requiredComp.default || requiredComp).dependencies || {});
          result.success.push(compPath);
        }
      } catch (e) {
        result.failures.push({
          error: e,
          compPath
        });

        if (e.code === "ENOENT" || e.code === "MODULE_NOT_FOUND") {
          console.error("Missing or unreadable custom component : ", resolvedPath); // eslint-disable-line no-console
        } else {
          // eslint-disable-next-line no-console
          console.error("Error when registering custom component : ", resolvedPath, e);
        }
      }
    });
    return result;
  }

  function handleMjmlConfig(configPathOrDir = process.cwd(), registerCompFn = _components.registerComponent) {
    const {
      mjmlConfig: {
        packages
      },
      componentRootPath,
      error
    } = readMjmlConfig(configPathOrDir);
    if (error) return {
      error
    };
    return handleMjmlConfigComponents(packages, componentRootPath, registerCompFn);
  }

  return exports;
}